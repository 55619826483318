let commonRouter = [
    {
        path: '/',
        redirect: {
            name: 'Dashboard'
        }
    },
    {
        path: '/404',
        name: 'NotFound',
        component: () => import('@/views/404/index.vue')
    },
    {
        path: '/403',
        name: 'Forbid',
        component: () => import('@/views/403/index.vue')
    },
    {
        path: '/print',
        name: 'Print',
        component: () => import('@/views/print/index.vue')
    }
]

export default commonRouter