let subjectObj: any = {
    '1': '数',
    '3': '物',
    '4': '化',
    '11': '英',
    '6': '英'
}
export function subjectFilter(input: string): string {
    if (input) {
        return subjectObj[input]
    } else {
        return ''
    }
}

let gradeArr: Array<Array<string>> = [
    // [],
    // ['', '一', '二', '三', '四', '五', '六'],
    // ['', '七', '八', '九'],
    // ['', '高', '高', '高'],
    // ['', '幼']
    [],
    ['', 'P1', 'P2', 'P3', 'P4', 'P5', 'P6'],
    ['', 'S1', 'S2', 'S3'],
    ['', 'S4', 'S5', 'S6'],
    ['', '幼']
]
export function gradeFilter(input: string): string {
    let _str: string = ''
    if (input) {
        let _arr = input.split('-').map(v => Number(v))
        _str = gradeArr[_arr[0]][_arr[1]]
    }
    return _str
}

export function formatStartTime(input: string): string {
    let _str: string = ''
    if (input) {
        _str = input.replace(/(\d{4})\-(\d{2})\-(\d{2})/, '$1-$2-$3')
    }
    return _str
}

export function formatEndTime(input: string, start: string): string {
    let _str: string = ''
    if (input) {
        let _sStr: string = start.substr(0, 4),
            _eStr: string = input.substr(0, 4);
        if (_sStr == _eStr) {
            _str = input.replace(/(\d{4})\-(\d{2})\-(\d{2})/, '$2-$3')
        } else {
            _str = formatStartTime(input)
        }
    }
    return _str
}

export function homeTextFilter(input: string): string {
    if (input && input == '首页') {
        return `网站${input}`
    } else {
        return input
    }
}

export function limitFive(input: string): string {
    if (input) {
        return input.substr(0, 5)
    } else {
        return '-'
    }
}
/**
 * 答案状态过滤器
 */
export function answerstateFilter(input: string): string {
    let _tempObj: any = {
        'CORRECTED': '已批改',
        'UNSUBMIT': 'Not submitted',
        'SUBMIT': 'Unapproved'
        // SUBMIT: '已提交'
    },
        _res: string = '';
    Object.keys(_tempObj).forEach((attr: string): void => {
        if (input == attr) {
            _res = _tempObj[attr]
        }
    })
    return _res
}
/**
 * 上课时间截取
 */
export function limitDateYMD(input: string): string {
    let _arr: Array<any> = [],
        _str: string = '';
    if (input) {
        _arr = input.split(' ')
    }
    if (_arr.length > 0) {
        _str = _arr[0]
    } else {
        _str = '-'
    }
    return _str
}

/**
 *
 */
let _numArr: Array<string> = [
    '', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十',
    '十一', '十二', '十三', '十四', '十五', '十六', '十七', '十八', '十九', '二十',
    '二十一', '二十二', '二十三', '二十四', '二十五', '二十六', '二十七', '二十八', '二十九', '三十',
    '三十一', '三十二', '三十三', '三十四', '三十五', '三十六', '三十七', '三十八', '三十九', '四十',
    '四十一', '四十二', '四十三', '四十四', '四十五', '四十六', '四十七', '四十八', '四十九', '五十',
]
export function timetableFilter(input: number): string {
    if (input) {
        // return `第${_numArr[input]}讲`
        return `Lesson ${input}`
    } else {
        return '--'
    }
}

/** */
export function termtypeFilter(input: any): string {
    let _res: string = ''
    switch (input) {
        case 11:
        case '11':
            _res = 'T1'
            break
        case 12:
        case '12':
            _res = 'T2'
            break
        case 13:
        case '13':
            _res = 'T3'
            break
        case 14:
        case '14':
            _res = 'T4'
            break
    }
    return _res
}

/**
 *
 */
export function createTimeFilter(input: string): string {
    let _str: string = ''
    if (!input) {
        _str = '-'
    } else {
        input = input.substr(0, 16)
        _str = input.replace(/\-/g, '/')
    }
    return _str
}
/**
 * 时间过滤器： 月日
 */
export function dateMDFilter(input: string): string {
    let _str: string = ''
    if (!input) {
        _str = '-'
    } else {
        let _arr: Array<string> = input.split(' ')
        if (_arr.length > 0) {
            let _s: string = _arr[0]
            if (_s && _s.length > 0) {
                _str = _s.replace(/(\d{4})\-(\d{2})\-(\d{2})/, '$2-$3')
            } else {
                _str = '-'
            }
        } else {
            _str = '-'
        }
    }
    return _str
}
/**
 * 时间过滤器：时分
 */
export function dateHMFilter(input: string): string {
    let _str: string = ''
    if (!input) {
        _str = '-'
    } else {
        let _arr: Array<string> = input.split(' ')
        if (_arr.length > 0) {
            let _s: string = _arr[1]
            if (_s && _s.length > 0) {
                _str = _s
            } else {
                _str = '-'
            }
        } else {
            _str = '-'
        }
    }
    return _str
}

/**
 * 选课中心选择日期后的过滤器
 */
export function restnumFilter(input: string): string {
    let _res: string = ''
    if (!input) {
        _res = '-'
    } else {
        let _arr: Array<string> = input.split('JL')
        if (input.indexOf('周') == 0) {
            _res = `${_arr[0].substr(0, 2)}${_arr[1].replace('-', '~').replace('(', '').replace(')', '')}`
        } else {
            _res = _arr[0]
        }
    }
    return _res
}

export function restnumFilter2(input: string): string {
    let _res: string = ''
    if (!input) {
        _res = '-'
    } else {
        let _arr: Array<string> = input.split('JL')
        if (input.indexOf('周') == 0 || input.indexOf('周') == 2) { // 这里是对于当前的数据结构进行替换过滤，需要从根本上解决传递的数据
            if (input.indexOf('周') == 0) {
            // _res = `${_arr[0].substr(0, 2)}${_arr[1].replace('-', '~').replace('(', '').replace(')', '')}`
            _res = `${_arr[0].replace('周一', 'Mon ').replace('周二', 'Tues ').replace('周三', 'Wed ').replace('周四', 'Thur ').replace('周五', 'Fri ').replace('周六', 'Sat ').replace('周日', 'Sun ').replace('晚上', 'P.M ').replace('上午', 'A.M ')}`
        }
        if (input.indexOf('周') == 2) {
            const data = _res.substr(2, 3)
            _res = _res.replace(data, '')
        }
            _res = _arr[0].replace('周一', 'Mon ').replace('周二', 'Tues ').replace('周三', 'Wed ').replace('周四', 'Thur ').replace('周五', 'Fri ').replace('周六', 'Sat ').replace('周日', 'Sun ').replace('晚上', 'P.M ').replace('上午', 'A.M ').replace('下午', 'P.M ')
            _res = _res.replace('周一', '').replace('周二', '').replace('周三', '').replace('周四', '').replace('周五', '').replace('周六', '').replace('周日', '')
        } else {
            _res = _arr[0].replace('周一', '').replace('周二', '').replace('周三', '').replace('周四', '').replace('周五', '').replace('周六', '').replace('周日', '').replace('晚上', ' P.M ').replace('上午', ' A.M ').replace('下午', ' P.M ')
        }

    }
    return _res
}

export function termtypeFilterFull(input: any): string {
    let _res: string = ''
    switch (input) {
        case 11:
        case '11':
            _res = 'Term-1'
            break
        case 12:
        case '12':
            _res = 'Term-2'
            break
        case 13:
        case '13':
            _res = 'Term-3'
            break
        case 14:
        case '14':
            _res = 'Term-4'
            break
    }
    return _res
}

export function phoneFilter(input: string): string {
    if (input) {
        return input.replace(/^(.{3}).*(.{4})/, '$1****$2')
    } else {
        return '-'
    }
}
export function mdrdhmFilter (input: string): string {
    let _res: string = ''
    if(!input) {
        _res = '-'
    } else {
        // start_time_str: "2021-03-11 13:00:00"
        _res = input.substr(5, 11)
        _res = _res.replace(/^(.{2})\-(.{2})(.{6})/, '$1月$2日$3')
    }
    return _res
}

export function mdrdhmFilter2 (input: string): string {
    let _res: string = ''
    if(!input) {
        _res = '-'
    } else {
        // start_time_str: "2021-03-11 13:00:00"
        _res = input.substr(5, 11)
        _res = _res.replace(/^(.{2})\-(.{2})(.{6})/, '$1-$2 $3')
    }
    return _res
}

export function hmFilter (input: string): string {
    let _res: string = ''
    if(!input) {
        _res = '-'
    } else {
        _res = input.substr(11, 5)
    }
    return _res
}
