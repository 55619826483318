import axios, { AxiosRequestConfig } from 'axios'

let api: string = ''
const isPro = process.env.NODE_ENV === 'production'

if (!isPro) {
    // api = '/api/service-dev/'
    api = '/api/'
} else {
    api = '/cloud-service-singapore/'
    // api = '/singapore-cloud-service-test/'
}
// api = isPro ? '' : '/api'
export class Caxios {
    // 通用选项
    private static commonOptions: any = {
        baseURL: process.env.VUE_APP_API_BASE_URL,
        responseType: 'json',
        timeout: 3000000
    };
    // 设置headers
    private static setHeaders(options: any = {}) {
        if (!options['headers']) {
            options['headers'] = {};
        }
        // 设置默认请求头内容类型
        if (options.method === 'POST') {
            if (!options['headers']['Content-Type']) {
                options['headers']['Content-Type'] = 'application/json; charset=UTF-8';
            }
        }
        return options;
    }
    public static async invoke<T>(
        options: AxiosRequestConfig
    ): Promise<any> {
        if (!options) return Promise.reject('axios请求参数配置不可以为空')

        options = Caxios.setHeaders(options)

        let method = options.method || 'GET',
            instance = axios.create(Caxios.commonOptions);

        // 请求拦截器
        instance.interceptors.request.use(
            (request: any): any => {
                if (method === 'POST') {
                    request.data = JSON.stringify(request.data);
                }
                request.url = request.url.replace('/api/', api)
                return request;
            },
            (err: any): any => {
                return Promise.reject(err);
            }
        );

        // 响应拦截器
        instance.interceptors.response.use(
            (response: any): any => {
                return response;
            },
            (err: any): any => {
                let result = err;
                // 取消处理
                if (err instanceof axios.Cancel) {
                    result = ''; // 返回空时，Prompt不提示
                }
                // 网络异常处理
                const errMsg = err.message || '';
                if (errMsg.indexOf('Network Error') > -1) {
                    result = '系统繁忙，请稍后重试';
                } else if (errMsg.indexOf('timeout of') > -1) {
                    result = '系统繁忙，请稍后重试';
                }
                return Promise.reject(result);
            }
        );

        let response = await instance.request(options)
        if (response.data.code == 10000) {
            return response.data.data
        } else {
            return response.data
        }
    }
    // GET方法请求
    public static async get<T>(
        options: AxiosRequestConfig
    ): Promise<T> {
        if (!options) return Promise.reject('axios配置参数不可以为空');
        options['method'] = 'GET';
        return await Caxios.invoke<T>(options);
    }

    // GET方法请求
    public static async delete<T>(
        options: AxiosRequestConfig
    ): Promise<T> {
        if (!options) return Promise.reject('axios配置参数不可以为空');
        options['method'] = 'DELETE';
        return await Caxios.invoke<T>(options);
    }

    // POST方法请求
    public static async post<T>(
        options: AxiosRequestConfig
    ): Promise<T> {
        if (!options) return Promise.reject('axios配置参数不可以为空');
        options['method'] = 'POST';
        return await Caxios.invoke<T>(options);
    }
}