
    import { Component, Vue, Prop } from 'vue-property-decorator'

    import { mapMutations } from 'vuex'

    @Component({
        name: 'MineDialog',
        methods: {
            ...mapMutations('common', ['setCommonComponentName'])
        }
    })
    export default class MineDialog extends Vue {
        setCommonComponentName!: any
        @Prop() width!: string
        @Prop() title!: string

        private closeEvent (): void {// 关闭弹窗的点击事件
            this.setCommonComponentName('')
        }
    }
