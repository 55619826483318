
    import { Component, Vue } from 'vue-property-decorator'
    import { mapGetters, mapMutations } from 'vuex'

    @Component({
        name: 'CopyRight',
        computed: mapGetters('common', ['navList']),
        methods: {
            ...mapMutations('common', ['setGoToTopFlag', 'setCommonComponentName'])
        }
    })
    export default class CopyRight extends Vue {
        navList!: Array<any>
        setGoToTopFlag!: any
        setCommonComponentName!: any

        private curNavList: Array<any> = []

        private timer: any = null

        private changeRoute (item: any): void {
            if(!item.componentName) {
                if(item.url.indexOf('http://') >= 0 || item.url.indexOf('https://') >= 0) {
                    window.open(item.url)
                } else {
                    let _this: any = this
                    if(_this.$route.path == item.url) {
                        this.setGoToTopFlag(true)
                    } else {
                        _this.$router.push(item.url)
                    }
                }
            } else {
                this.setCommonComponentName(item.componentName)
            }
        }

        beforeMount() {
            this.curNavList = [ ...this.navList ].filter(v => v.text != '客户端下载')
        }
    }
