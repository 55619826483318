import { GetterTree } from 'vuex'
import { ActionContext } from './../interface'
// import { getYearList } from './../../utils/common'

export interface State {
    yearList: Array<string>;
    grade: Array<any>;
    subject: Array<any>;
    termtype: Array<any>;
    curriculumObj: any;
    hotCourseList: Array<any>;
    teachersList: Array<any>;
    selectOptionsStable: any;
    selectObj: any;
    canSignupFlag: boolean;
    delSelectAttr: string;
    markerList: Array<any>;
    subjectLevel: string;
    loadMoreFlag: boolean;
    unSelectFlagStr: string
}

let state: State = {
        yearList: [],
        grade: [],
        subject: [],
        termtype: [
            {
                text: '不限',
                id: ''
            },
            {
                text: 'Term-4',
                id: '14'
            },
            {
                text: 'Term-1',
                id: '11'
            },
            {
                text: 'Term-2',
                id: '12'
            },
            {
                text: 'Term-3',
                id: '13'
            },
            {
                text: '短期课',
                id: '21'
            },
            {
                text: '公益课',
                id: 'allactive'
            },
            {
                text: '讲座',
                id: 'leason'
            },
            {
                text: '考试',
                id: 'exam'
            }
        ],
        curriculumObj: {},
        hotCourseList: [],
        teachersList: [],
        selectOptionsStable: {},
        selectObj: {},
        canSignupFlag: false,
        delSelectAttr: '',
        markerList: [
            { title: '方田教育-合肥分校', area: '亳州路校区', address: '庐阳区亳州路301号金地大厦', text: '亳州路', lng: 117.2646182614, lat: 31.8880301682 },
            { title: '方田教育-合肥分校', area: '五里墩校区', address: '蜀山区长江西路与合作化南路交口金域国际', text: '五里墩', lng: 117.2563281597, lat: 31.8579923217 },
            { title: '方田教育-合肥分校', area: '千城大厦校区', address: '滨湖新区徽州大道与朱雀街交口千城大厦', text: '千城大厦', lng: 117.2852400000, lat: 31.7449200000 },
            { title: '方田教育-合肥分校', area: '市府广场校区', address: '庐阳区安庆路79号天徽大厦B座', text: '市府广场', lng: 117.2924650938, lat: 31.8692637419 },
            { title: '方田教育-合肥分校', area: '天鹅湖校区', address: '政务区潜山路与高河东路交口绿地蓝海C座', text: '天鹅湖', lng: 117.239128, lat: 31.828009 },
            { title: '方田教育-合肥分校', area: '大溪地校区', address: '蜀山区黄山路与岳西路交口向北50米十里庙社区综合楼北楼', text: '大溪地', lng: 117.232094, lat: 31.849359 },
            { title: '方田教育-合肥分校', area: '巴黎都市校区', address: '湖北路佳源巴黎都市2期商业4栋201', text: '巴黎都市', lng: 117.313728, lat: 31.74214 },
            { title: '方田教育-合肥分校', area: '天王巷校区', address: '庐阳区天王巷4号', text: '天王巷', lng: 117.27632, lat: 31.866521 },
            { title: '方田教育-合肥分校', area: '南薰门桥校区', address: '徽州大道376号', text: '南薰门桥', lng: 117.292764, lat: 31.855526 },
            { title: '方田教育-合肥分校', area: '义井路校区', address: '义井路与合瓦路交口向西50米谊品生鲜旁', text: '义井路', lng: 117.29074, lat: 31.88944 },
            { title: '方田教育-合肥分校', area: '杏花公园校区', address: '蒙城路79号中兴大厦三楼', text: '杏花公园', lng: 117.284268, lat: 31.873541 },
            { title: '方田教育-合肥分校', area: '玫瑰园校区', address: '合肥市经开区繁华大道与芙蓉路交叉口决策大厦2号楼', text: '玫瑰园', lng: 117.197499, lat: 31.788618 },
            { title: '方田教育-合肥分校', area: '栢景湾校区', address: '亳州路与北一环交口天庆大厦3层', text: '栢景湾', lng: 117.270145, lat: 31.884381 },
            { title: '方田教育-合肥分校', area: '南薰门桥校区', address: '徽州大道376号', text: '南薰门桥', lng: 117.29277, lat: 31.855601 },
            { title: '方田教育-合肥分校', area: '城隍庙校区', address: '庐阳区霍邱路87号（城隍庙大世界喜品城5楼）', text: '城隍庙', lng: 117.286039, lat: 31.870201 },
            { title: '方田教育-合肥分校', area: '稻香楼校区', address: '稻香楼', text: '稻香楼', lng: 117.277473, lat: 31.858985 },
            { title: '方田教育-合肥分校', area: '千城大厦校区', address: '滨湖新区徽州大道与朱雀街交口千城大厦', text: '千城大厦', lng: 117.291277, lat: 31.751115 },
            { title: '方田教育-芜湖分校', area: '星隆国际校区', address: '芜湖市镜湖区星隆国际城9号楼', text: '星隆国际', lng: 118.408371, lat: 31.259294 },
            { title: '方田教育-芜湖分校', area: '城南润地校区', address: '九华南路润地商业广场1号楼302', text: '城南润地', lng: 117.291277, lat: 31.751115 },
            { title: '方田教育-马鞍山分校', area: '马鞍山分校', address: '马鞍山市湖北东路8号创客园25栋1~3层', text: '创客园', lng: 118.519275, lat: 31.702766 },
            { title: '方田教育-安庆分校', area: '安庆分校', address: '安庆市大观区德宽路161-2号怡庭公寓一楼（徽商银行东侧）', text: '德宽路', lng: 117.045892, lat: 30.521599 },
            { title: '方田教育-南京分校', area: '南京分校', address: '南京市鼓楼区江东北路305号', text: '雄狮大厦', lng: 118.745738, lat: 32.064546 }
        ],
        subjectLevel: '',
        loadMoreFlag: false,
        unSelectFlagStr: ''
    },
    getters: GetterTree<State, any> = {
        yearList: (state: State) => state.yearList,
        grade: (state: State) => state.grade,
        subject: (state: State) => state.subject,
        termtype: (state: State) => state.termtype,
        curriculumObj: (state: State) => state.curriculumObj,
        hotCourseList: (state: State) => state.hotCourseList,
        teachersList: (state: State) => state.teachersList,
        selectOptionsStable: (state: State) => state.selectOptionsStable,
        selectObj: (state: State) => state.selectObj,
        canSignupFlag: (state: State) => state.canSignupFlag,
        delSelectAttr: (state: State) => state.delSelectAttr,
        markerList: (state: State) => state.markerList,
        subjectLevel: (state: State) => state.subjectLevel,
        loadMoreFlag: (state: State) => state.loadMoreFlag,
        unSelectFlagStr: (state: State) => state.unSelectFlagStr
    },
    mutations = {
        setYearList(state: State, yearList: Array<string>): void {
            state.yearList = []
            state.yearList = yearList
        },
        setGrade(state: State, grade: Array<any>): void {
            state.grade = grade
            state.grade.unshift({ text: 'All grades', id: '' })
        },
        setSubject(state: State, subject: Array<any>): void {
            console.log(',,,......', subject)
            state.subject = subject.filter((item: any): boolean => { return item.id != '2' })
            state.subject.unshift({ text: 'All subjects', id: '' })
        },
        setCurriculumObj(state: State, curriculumObj: any): void {
            state.curriculumObj = curriculumObj
        },
        setHotCourseList(state: State, hotCourseList: Array<any>): void {
            state.hotCourseList = hotCourseList
        },
        setTeachersList(state: State, teachersList: Array<any>): void {
            state.teachersList = teachersList
        },
        setSelectOptionsStable (state: State, selectOptionsStable: any): void {
            state.selectOptionsStable = selectOptionsStable
        },
        setSelectObj (state: State, selectObj: any): void {
            state.selectObj = selectObj
        },
        setCanSignupFlag (state: State, canSignupFlag: boolean): void {
            state.canSignupFlag = canSignupFlag
        },
        setDelSelectAttr (state: State, attr: string): void {
            state.delSelectAttr = attr
        },
        setSubjectLevel (state: State, subjectLevel: string): void {
            state.subjectLevel = subjectLevel
        },
        setLoadMoreFlag (state: State, loadMoreFlag: boolean): void {
            state.loadMoreFlag = loadMoreFlag
        },
        setUnSelectFlagStr (state: State, unSelectFlagStr: string): void {
            state.unSelectFlagStr = unSelectFlagStr
        }
    },
    actions = {
        async setAjaxData(context: ActionContext, ajaxData: any): Promise<void> {
            let { commit } = context
            commit('setGrade', ajaxData.GRADE)
            commit('setSubject', ajaxData.SUBJECT)
        },
        async setCurriculumObj(context: ActionContext, curriculumObj: any): Promise<void> {
            let { commit } = context
            commit('setCurriculumObj', curriculumObj)
        },
        async setHotCourseList(context: ActionContext, hotCourseList: Array<any>): Promise<void> {
            let { commit } = context
            commit('setHotCourseList', hotCourseList)
        },
        async setTeachersList(context: ActionContext, teachersList: Array<any>): Promise<void> {
            let { commit } = context
            commit('setTeachersList', teachersList)
        },
        async setSelectOptionsStable(context: ActionContext, selectOptionsStable: any): Promise<void> {
            let { commit } = context
            commit('setSelectOptionsStable', selectOptionsStable)
        },
        async setYearList(context: ActionContext, yearList: Array<any>): Promise<void> {
            let { commit } = context
            commit('setYearList', yearList)
        }
    },
    course = {
        namespaced: true,
        state,
        getters,
        mutations,
        actions
    };

export default course
