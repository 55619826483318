/**
 * 获取屏幕宽度
 */
import store from './../store'
import { Caxios } from './axios';
export function getClientWidth (): void {
    let _width: number = document.body.clientWidth || document.documentElement.clientWidth
    store.dispatch('common/setClientWidth', _width)
}

/**
 * 获取年份列表
 */
export function getYearList (): Array<string> {
    let _res: Array<string> = [],
        _date: any = new Date(),
        _MM = _date.getMonth(),
        yy = _MM == 11 ? _date.getFullYear() + 1 : _date.getFullYear();
    for (; yy >= 2019; yy--) {
        _res.push(String(yy))
    }
    _res.unshift('不限')
    return _res
}

/**
 * 时间段获取中文
 */
export function getCNFromPriod (input: any): string {
    let _res: string = ''
    if(input && input.length > 0) {
        var reg: any = /[\u4e00-\u9fa5]/g;   
        _res = input.match(reg).join("")
    }
    return _res
}

/**
 * JSON.parse 处理数据
*/
export function parseData (data: any): any {
   let parsedata: any
   if(typeof data == 'string') {
       parsedata = JSON.parse(data)
   }else if (typeof data == 'object') {
       parsedata = data
   }
   return parsedata
}

/**
 * 获取分校列表
*/
export async function getBranchschools (): Promise<any> {
    const result = await Caxios.get({ url: '/api/branchschools' })
    store.dispatch('common/setBranchschools', result)
} 