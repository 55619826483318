
    import { Component, Vue } from 'vue-property-decorator'
    import { checkPhone, checkSPhone } from './../../utils/validate'

    import { mapGetters, mapMutations, mapActions } from 'vuex'
    import { Caxios } from './../../utils/axios'

    import UseName from './../username/index.vue'
    import UsePwd from './../password/index.vue'
    import SmsCode from './../msgCode/index.vue'
    

    @Component({
        name: 'MineRegister',
        components: { UseName, SmsCode, UsePwd },
        computed: {
            ...mapGetters('common', ['branchschools']),
            ...mapGetters('course', ['grade'])
        },
        methods: {
            ...mapMutations('common', ['setRegistrationAgreementFlag', 'setCommonComponentName'])
        }
    })
    export default class MineRegister extends Vue {
        branchschools!: any
        grade!: any
        setRegistrationAgreementFlag!: any
        setCommonComponentName!: any
        private usernameMsg: string = ''
        private usernameMsg2: string = ''
        private smscodeMsg: string = ''
        private passwordMsg: string = ''
        private gradeMsg: string = ''
        private locationMsg: string = ''
        private schoolMsg: string = ''

        private loginForm: any = {
            branch_school_id: '',
            grade: '',
            name: '',
            password: '',
            phone: '',
            sex: 1,
            smscode: ''
        }
        private province: string = ''
        private city: string = ''
        private curGrade: string = ''
        private schoolarea: string = ''
        private provinceFlag: boolean = false
        private cityFlag: boolean = false
        private gradeFlag: boolean = false
        private hasSelected: boolean = false
        private schoolFlag: boolean = false

        private provinceList: Array<any> = [
            { label: '请选择省', value: '' }
        ]
        private cityList: Array<any> = [
            { city: '请选择市' }
        ]
        private gradeList: Array<any> = [
            { text: 'Please select a grade' }
        ]
        private schoolList: Array<any> = [
            { text: '请选择分校' }
        ]
        private provinceActiveIndex: number = -1
        private cityActiveIndex: number = -1
        private gradeActiveIndex: number = -1
        private schoolActiveIndex: number = -1

        private usenameCb (obj: any): void {
            let { phone, status } = obj
            // this.loginForm.phone = phone
            if(checkSPhone(phone)) {
                this.usernameMsg = ''
                this.loginForm.phone = phone
            } else {
                this.usernameMsg = 'Incorrect mobile number format'
              this.loginForm.phone = ''
            }
            if(status == 0) {
                this.usernameMsg = ''
            }
        }
        private usenameCb2 (obj: any): void {
            let { phone, status } = obj
            this.loginForm.name = phone
            if(phone) {
                this.usernameMsg2 = ''
            } else {
                this.usernameMsg2 = 'Name cannot be empty'
            }
            if(status == 0) {
                this.usernameMsg2 = ''
            }
        }
        private usepwdCb (obj: any): void {
            let { password, status } = obj
            this.loginForm.password = password
            if(password) {
                if(password.length < 8 || password.length > 12) {
                    this.passwordMsg = 'Wrong password format'
                } else {
                    this.passwordMsg = ''
                }
            } else {
                this.passwordMsg = 'Password cannot be empty'
            }

            if(status == 0) {
                this.passwordMsg = ''
            }
        }
        private msgcodeCb (obj: any): void {
            let { smscode, status } = obj
            this.loginForm.smscode = smscode

            if(smscode) {
                this.smscodeMsg = ''
            } else {
                this.smscodeMsg = 'Verification code cannot be empty'
            }
            if(status == 0) {
                this.smscodeMsg = ''
            }
        }
        private provinceEvent (): void {
            this.provinceFlag = !this.provinceFlag
        }
        private cityEvent (): void {
            this.cityFlag = !this.cityFlag
        }
        private gradeEvent (): void {
            this.gradeFlag = !this.gradeFlag
        }
        private schoolEvent (): void {
            this.schoolFlag = !this.schoolFlag
        }
        private provinceItemEvent (item: any, index: number): void {
            this.province = item.label
            this.provinceActiveIndex = index
            this.cityList = [ { city: '请选择市' } ]
            if(index > 0) {
                this.branchschools[index - 1].list.forEach((v: any): void => {
                    this.cityList.push(v)
                })
            }
            this.provinceFlag = false
            this.locationMsg = index == 0 ? '省不能为空' : ''
        }
        private cityItemEvent (item: any, index: number): void {
            this.city = item.city
            this.cityActiveIndex = index
            this.loginForm.branch_school_id = item.id
            this.cityFlag = false
            this.locationMsg = index == 0 ? '市不能为空' : ''
        }

        private gradeItemEvent (item: any, index: number): void {
            this.curGrade = item.text
            this.gradeActiveIndex = index
            this.loginForm.grade = item.id
            this.gradeMsg = index == 0 ? 'Grade cannot be empty' : ''
            this.gradeFlag = false
        }
        private schoolItemEvent (item: any, index: number): void {
            this.schoolarea = item.name
            this.schoolActiveIndex = index
            this.loginForm.branch_school_id = item.id
            this.schoolMsg = index == 0 ? '分校不能为空' : ''
            this.schoolFlag = false
        }
        private curRegisterEvent (): any {
            let _this: any = this
            if(!this.loginForm.phone) {
                this.usernameMsg = 'Mobile number cannot be empty'
                _this.$message.warning('Mobile number cannot be empty')
                return
            }
            if(!this.loginForm.smscode) {
                this.smscodeMsg = 'Verification code cannot be empty'
                _this.$message.warning('Verification code cannot be empty')
                return
            }
            if(!this.loginForm.password) {
                this.passwordMsg = 'Password cannot be empty'
                _this.$message.warning('Password cannot be empty')
                return
            }
            // if(!this.province) {
            //     this.locationMsg = '省不能为空'
            //     _this.$message.warning('省不能为空')
            //     return
            // }
            // if(!this.city) {
            //     this.locationMsg = '市不能为空'
            //     _this.$message.warning('市不能为空')
            //     return
            // }
            if(!this.loginForm.name) {
                this.usernameMsg2 = 'Name cannot be empty'
                _this.$message.warning('Name cannot be empty')
                return
            }
            if(!this.loginForm.grade) {
                this.gradeMsg = 'Grade cannot be empty'
                _this.$message.warning('Grade cannot be empty')
                return
            }
            if(!this.hasSelected) {
                _this.$message.warning('Please read and agree to the User Agreement before registering')
                return
            }
            // this.loginForm.branch_school_id = '2'
            this.loginForm.registChannel = 'wy'
            this.registerEvent()
        }
        private async registerEvent () {
            let _result: any = await Caxios.post({ url: '/api/official/register', data: this.loginForm }),
                _this: any = this;
            if(!_result.msg) {
                _this.$message.success('注册成功')
                this.setCommonComponentName('')
            } else {
                _this.$message.warning(_result.msg)
            }
        }
        private signupEvent (): void {
            this.setRegistrationAgreementFlag(true)
        }
        private async getSchools () {
            const data: any = await Caxios.get({ url: '/api/branchschool/list?pageType=english', data: '' })
            if (data) {
               this.schoolList = data
               this.schoolList.unshift({ text: '请选择分校' })
            }
        }
        beforeMount() {
            this.grade.forEach((item: any, index: number): void => {
                if(index > 0) {
                    this.gradeList.push(item)
                }
            })
            this.branchschools.forEach((item: any, index: number): void => {
                let _tempP: any = {}
                _tempP.label = item.province
                _tempP.value = ''
                this.provinceList.push(_tempP)
            })
        }
        mounted () {
            this.getSchools()
        }
    }
