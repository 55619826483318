import Vue from 'vue'
import { Progress, Divider, Select, Modal, Spin } from 'ant-design-vue';
import App from './App.vue'
import router from './router'
import store from './store'


import './assets/css/reset.css'
import './assets/css/common.css'
import './assets/font/iconfont.css'
import 'ant-design-vue/dist/antd.css'

import { Caxios } from './utils/axios'

import { getClientWidth } from './utils/common'

async function getBranchschools () {
    const result = await Caxios.get({ url: '/api/branchschools' })
    store.dispatch('common/setBranchschools', result)
}
getBranchschools()

getClientWidth()
window.onresize = () => {
    getClientWidth()
}

// 初始化年级、学科
async function initSelectPanel () {
    let result: any = await Caxios.get({ url: '/api/datadict/multiply/SUBJECT,GRADE' })
    store.dispatch('course/setAjaxData', result)
}

initSelectPanel()

let fangtian_personal_Data: any = localStorage.getItem('fangtian_personal_Data')
if(fangtian_personal_Data) {
    store.dispatch('personal/setPersonalData', JSON.parse(fangtian_personal_Data))
}


/* 全局引入过滤器 */
import * as filters from './utils/filters'
Object.keys(filters).forEach((key: string): void => {
    let _filters: any = filters
    Vue.filter(key, _filters[key])
})

// message弹窗
import message from './components/message/message'
Vue.use(message);
Vue.use(Progress);
Vue.use(Divider);
Vue.use(Select);
Vue.use(Modal);
Vue.use(Spin);

// 确认弹窗
// import confirm from './components/confirm/index'
// Vue.use(confirm);

import theConfirm from './components/confirm/index.js'
Vue.prototype.$confirm = theConfirm;


Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
