import Vue from 'vue';
import confirm from './index.vue';

let confirmConstructor = Vue.extend(confirm);
/**
 * options 确认框的配置项
 * title 标题
 * sunTitle 次标题
 * width 确认框主题宽度
 * showClose 右上角是否显示关闭按钮
 * btns 按钮
 *  sure 确认
 *  cancel 取消
 */
let theConfirm = function (options) {
    return new Promise((res, rej) => { //promise封装，ok执行resolve，no执行rejectlet
        let confirmDom = new confirmConstructor({
            el: document.createElement('div')
        })
        document.body.appendChild(confirmDom.$el);  //new一个对象，然后插入body里面

        confirmDom.options = options;   //为了使confirm的扩展性更强，这个采用对象的方式传入，所有的字段都可以根据需求自定义
        
        Vue.nextTick(() => {
            confirmDom.isShow = true;
        });
        confirmDom.sure = function () {
            res(confirmDom.text)
            confirmDom.isShow = false
        }
        confirmDom.cancel = function () {
            rej(confirmDom.text)
            confirmDom.isShow = false
        }
    })
}

  export default theConfirm;
