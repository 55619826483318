
    import { Component, Vue, Watch, Prop, Emit } from 'vue-property-decorator'
    import { Caxios } from './../../utils/axios'
    @Component({
        name: 'SmsCode'
    })
    export default class SmsCode extends Vue {
        @Prop() phone!: string
        @Prop() placeholder!: string
        @Prop() branchSchoolId!: string

        private smscode: string = ''
        private status: number = 0
        private smscodeStr: string = 'Send'
        private restNum: number = 60
        private timer: any = null
        private curPhone: string = ''
        private branchId: string = ''

        @Watch('phone', { immediate: true, deep: true })
        phoneWatch(newVal: string): void {
            this.curPhone = newVal
            console.log('手机号。，，，，，，，，，，，，，，，，，')
        }
        @Watch('branchSchoolId', { immediate: true, deep: true })
        branchWatch(newVal: string): void {
            this.branchId = newVal
        }
        
        private focusEvent(): void {
            this.status = 0
            this.msgcodeCb(0)
        }
        @Emit()
        private msgcodeCb(status: number): any {
            return { smscode: this.smscode, status: status }
        }
        private blurEvent (): void {
            if(this.smscode) {
                this.status = 1
            } else {
                this.status = 2
            }
            this.msgcodeCb(1)
        }
        private getSmscodeEvent (): void {
            if(!this.timer && this.curPhone) {
                this.timer = setInterval(() => {
                    if(this.restNum > 1) {
                        this.restNum -= 1
                        this.smscodeStr = `Verification Code(${this.restNum})`
                    } else {
                        this.restNum = 60
                        this.smscodeStr = '重新发送'
                        clearInterval(this.timer)
                        this.timer = null
                    }
                }, 1000)
                this.smsAjax()
            }
        }
        private async smsAjax () {
            let _result: any = await Caxios.get({ url: `/api/official/smscode/${this.curPhone}?branchSchoolId=${this.branchId}` })
            console.log(_result)
        }
    }
