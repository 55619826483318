
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters, mapMutations, mapActions } from "vuex";

import { Caxios } from "./../../../utils/axios";

import { qqGroupItem, navItem } from "./../../../store/interface";

@Component({
  name: "LayLocation",
  computed: {
    ...mapGetters("common", [
      "clientWidth",
      "mainWidth",
      "cityPosition",
      "qqGroupList",
      "navList"
    ]),
    ...mapGetters("personal", ["personalData"]),
    ...mapGetters("course", ["grade"])
  },
  methods: {
    ...mapMutations("common", ["setCommonComponentName"]),
    ...mapActions("personal", ["setPersonalData"])
  }
})
export default class LayLocation extends Vue {
  clientWidth!: number;
  mainWidth!: number;
  cityPosition!: any;
  grade!: Array<any>;
  qqGroupList!: Array<qqGroupItem>;
  navList!: Array<any>;
  personalData!: any;
  setCommonComponentName!: any;
  setPersonalData!: any;

  private qqGroupFlag = false;
  private leftPos = "50%";
  private curQqGroupList: Array<qqGroupItem> = [];
  private curCityPosition: any = {};
  private activeIndex = 0;
  private loginFlag = false;
  private curNavList: Array<any> = [];

  private gradeInfo: any = {
    text: "全部年级",
    id: ""
  };
  private languageInfo: any = {
    text: "English",
    id: "en"
  };
  private languageList = [
    {
      label: "中文",
      value: "zh"
    },
    {
      label: "English",
      value: "en"
    }
  ];
  private curGradeList: Array<any> = [];
  private gradeInfoFlag = false;
  private languageFlag = false;

  private qqMouseEvent(): void {
    this.qqGroupFlag = !this.qqGroupFlag;
  }
  @Watch("navList", { immediate: true, deep: true })
  navListWatch(newVal: Array<any>): void {
    if (newVal && Array.isArray(newVal) && newVal.length > 0) {
      this.curNavList = newVal.filter((itm: any): boolean => {
        return !itm.componentName && itm.text !== "加入我们";
      });
    }
  }
  // 处理QQ群的位置
  @Watch("clientWidth", { immediate: true, deep: true })
  clientWidthWatch(newVal: number): void {
    let _right = 0; // 宽度是1200的时候距离右端的距离
    if (newVal >= 1680) {
      this.leftPos = "-239px";
    } else if (newVal <= 1200) {
      this.leftPos = "-456px";
    } else {
      _right = (newVal - this.mainWidth) / 2;
      this.leftPos = `-${480 - _right}px`;
    }
  }

  @Watch("cityPosition", { immediate: true, deep: true })
  cityPositionWatch(newVal: any): void {
    this.curCityPosition = newVal;
  }
  @Watch("qqGroupList", { immediate: true, deep: true })
  qqGroupListWatch(newVal: any): void {
    this.curQqGroupList = newVal;
  }
  @Watch("$route", { immediate: true, deep: true })
  routeWatch(newVal: any): void {
    this.activeIndex = -1;
    this.curNavList.forEach((v: any, i: number): void => {
      if (v.url == newVal.path) {
        this.activeIndex = i;
      }
    });
    if (newVal.path == "/course/detail") {
      this.activeIndex = 1;
    }
    if (newVal.path == "/personal/index") {
      this.activeIndex = this.curNavList.length;
    }
    if (newVal.path == "/nearbyCampus/index") {
      this.activeIndex = 0;
    }
  }
  @Watch("personalData", { immediate: true, deep: true })
  personalDataWatch(newVal: any): void {
    if (newVal && newVal.id) {
      this.loginFlag = true;
    } else {
      this.loginFlag = false;
    }
  }

  @Watch("grade", { immediate: true, deep: true })
  gradeWatch(newVal: Array<any>): void {
    if (newVal && Array.isArray(newVal) && newVal.length > 0) {
      this.curGradeList = [...newVal];
      // this.curGradeList.map((item: any): void => {
      //     if(item.text == '不限') {
      //         item.text = '全部年级'
      //     }
      // })
      if (this.personalData.id && this.personalData.grade) {
        this.curGradeList.forEach((item: any): void => {
          if (item.id == this.personalData.grade) {
            this.gradeInfo = { ...item };
          }
        });
      }
    }
  }
  // 点击logo前往 首页
  private toHome(): void {
    console.log("去首页");
    this.$router.push({ path: "/" });
  }
  private showDialogEvent(copmName: string): void {
    this.setCommonComponentName(copmName);
  }
  private joinEvent(): void {
    window.open("https://job.fangtian.me/");
  }
  private personalEvent(copmName: string): void {
    // this.setCommonComponentName(copmName)
    this.activeIndex = this.curNavList.length;
    const _this: any = this;
    _this.$router.push("/personal");
  }
  private logoutEvent(): void {
    Object.keys(localStorage).forEach((attr: string): void => {
      if (
        attr.indexOf("fangtian_") >= 0 &&
        attr != "fangtian_user" &&
        attr != "fangtian_pwd"
      ) {
        localStorage.removeItem(attr);
      }
    });
    this.setPersonalData("");
    const _this: any = this;
    _this.$router.push("/dashboard/index");
  }
  private changeGrade(item: any): void {
    this.gradeInfo = { ...item };
    this.gradeInfoFlag = false;
  }
  private changeLanguage(item: any): void {
    const url = this.$route.path
    const params = this.$route.query
    const qs = require('qs')
    const rl = (Object.keys(params).length > 0 ) ? (url+ '?' +qs.stringify(params)):url
    if (item === "zh") {
      this.languageFlag = false;
      window.open(`https://kangaroo.study/cn${rl}`, "_parent", "location=yes");
      // window.open(`https://pre.fangtian.me/cn${rl}`, '_parent', 'location=yes')
    }
  }

  private openUrl(item: navItem, index: number): void {
    if (item.url.indexOf("http://") >= 0 || item.url.indexOf("https://") >= 0) {
      window.open(item.url);
    } else {
      this.activeIndex = index;
      const _this: any = this;
      _this.$router.push(item.url);
    }
  }
}
