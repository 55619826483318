
    import { Component, Vue, Watch, Emit } from 'vue-property-decorator'

    import { mapGetters, mapMutations } from 'vuex'

    import { Caxios } from './../../utils/axios'

    @Component({
        name: 'CityPos',
        computed: mapGetters('common', ['clientWidth', 'mainWidth', 'branchschools', 'cityPosition', 'qqGroupList', 'navList']),
        methods: {
            ...mapMutations('common', ['setCityPosition', 'setBranchSchoolId'])
        }
    })
    export default class CityPos extends Vue {
        branchschools!: Array<any>
        setCityPosition!: any
        setBranchSchoolId!: any

        private curBranchschools: Array<any> = []

        @Watch('branchschools', { immediate: true, deep: true })
        branchschoolsWatch(newVal: Array<any>): void {
            this.curBranchschools = newVal
        }
        @Emit()
        private closeEvent (): void {// 关闭弹窗的点击事件
        }
        private changeBranchschool (itm: any): void {
            this.setCityPosition(itm)
            this.setBranchSchoolId(itm.id)
            localStorage.setItem('fangtian_curCityPosition', JSON.stringify(itm))
            this.closeEvent()
        }
    }
