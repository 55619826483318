import { GetterTree } from 'vuex'
import { aboutUsNavItem } from './../interface'
export interface State {
        anoutUsNav: Array<aboutUsNavItem>
    }

let state: State = {
        anoutUsNav: [
            { text: '企业介绍', compName: 'EnterpriseIntroduction' },
            { text: '名师介绍', compName: 'TeacherIntroduced' },
            { text: '课程体系', compName: 'CurriculumSystem' },
            // { text: '帮助中心', compName: 'HelpCenter' }
        ]
    },
    getters: GetterTree<State, any> = {
        anoutUsNav: (state: State) => state.anoutUsNav
    },
    mutations = {
        // setAnoutUsNav(state: State, anoutUsNav: Array<string>): void {
        //     state.anoutUsNav = anoutUsNav
        // },
    },
    actions = {
    },
    aboutUs = {
        namespaced: true,
        state,
        getters,
        mutations,
        actions
    };

export default aboutUs
