import vue from 'vue'
import Message from './index.vue'

let MsgClass: any = vue.extend(Message),
    MsgMain: any = {
        show: (text: string, type: string, duration: number): void => {
            let instance: any = new MsgClass()
            instance.$mount(document.createElement('div'))
            document.body.appendChild(instance.$el)
            instance.type = type
            instance.text = text
            instance.isShow = true
            setTimeout(() => {
                // instance.top = '20px'
                instance.top = '50%'
            }, 1)
            
            setTimeout(() => {
                instance.top = '-100%'
            }, duration + 301)
            setTimeout(() => {
                instance.isShow = false
            }, duration + 602)
        },
        success: (text: string, duration: number = 2000): void => {
            MsgMain.show(text, 'success', duration)
        },
        error: (text: string, duration: number = 2000): void =>{
            MsgMain.show(text, 'error', duration)
        },
        warning: (text: string, duration: number = 2000): void =>{
            MsgMain.show(text, 'warning', duration)
        }
    }
function message (): void {
    vue.prototype.$message = MsgMain
}
export default message