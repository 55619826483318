
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import { mapGetters, mapMutations } from 'vuex'
    import { Caxios } from './../../utils/axios'
    @Component({
        name: 'HeadmasterMailbox',
        computed: mapGetters('common', ['branchSchoolId']),
        methods: {
            ...mapMutations('common', ['setCommonComponentName'])
        }
    })
    export default class HeadmasterMailbox extends Vue {
        branchSchoolId!: number
        setCommonComponentName!: any
        private phone: string = ''
        private content: string = ''
        private branchSchool: string = ''
        private branchtext: string = '校区'
        private msgcode: string = ''
        private showNum: string = ''
        private schoolList: Array<any> = [
            { branchtext: '合肥分校', branchSchool: '合肥', selectId: 2 },
            { branchtext: '芜湖分校', branchSchool: '芜湖', selectId: 5 },
            { branchtext: '马鞍山分校', branchSchool: '马鞍山', selectId: 6 },
            { branchtext: '安庆分校', branchSchool: '安庆', selectId: 7 },
            { branchtext: '南京分校', branchSchool: '南京', selectId: 8 }
        ]
        private schoolListFlag: boolean = false

        private mycan: any = null// canvas元素
        private con: any = null// 画布元素
        private conW: any = null// 画布的宽度
        private conH: any = null// 画布的高度
        /*
            if(selectId === '2'){
                branchtext = '合肥';
            }
            if(selectId === '5'){
                branchtext = '芜湖';
            }
            if(selectId === '6'){
                branchtext = '马鞍山';
            }
            if(selectId === '7'){
                branchtext = '安庆';
            }
            if(selectId === '8'){
                branchtext = '南京';
            }
        */
        /**
         *  param["phone"]=username;
            param["content"]=desc;
            param["branchSchool"]=branchtext;
         */
        @Watch('branchSchoolId', { immediate: true, deep: true })
        branchSchoolIdWatch(newVal: number): void {
            if(newVal && newVal > 0) {
                this.schoolList.forEach((item: any, index: number): void => {
                    if(item.selectId == newVal) {
                        this.selectSchool(item)
                    }
                })
            }
        }
        // 选择校区
        private selectSchool (item: any): void {
            this.branchSchool = item.branchSchool
            this.branchtext = item.branchtext
            this.schoolListFlag = false
        }
        private drawFrame(): void {
            this.con.clearRect(0, 0, this.conW, this.conH)
            this.showNum = ''
            let _arr: Array<string> = [],
                aCode: Array<string> = [ 'A', 'B', 'C', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'W', 'X', 'Y', 'Z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0' ],
                aLength = aCode.length;
            for (let i = 0; i <= 3; i++) {
                let j = Math.floor(Math.random() * aLength),//获取到随机的索引值
                    deg = Math.random() * 30 * Math.PI / 180,//产生0~30之间的随机弧度
                    txt = aCode[j];//得到随机的一个内容
                this.showNum += txt.toLowerCase()

                let x = 10 + i * 20,//文字在canvas上的x坐标
                    y = 20 + Math.random() * 8;//文字在canvas上的y坐标
                this.con.font = "bold 23px 微软雅黑";
                this.con.translate(x, y);
                this.con.rotate(deg);
                this.con.fillStyle = this.randomColor();
                this.con.fillText(txt, 0, 0);
                this.con.rotate(-deg);
                this.con.translate(-x, -y);
            }

            for (var i = 0; i <= 5; i++) { //验证码上显示线条
                this.con.strokeStyle = this.randomColor();
                this.con.beginPath();
                this.con.moveTo(Math.random() * this.conW, Math.random() * this.conH);
                this.con.lineTo(Math.random() * this.conW, Math.random() * this.conH);
                this.con.stroke();
            }
            for (var i = 0; i <= 30; i++) { //验证码上显示小点
                this.con.strokeStyle = this.randomColor();
                this.con.beginPath();
                var x = Math.random() * this.conW;
                var y = Math.random() * this.conH;
                this.con.moveTo(x, y);
                this.con.lineTo(x + 1, y + 1);
                this.con.stroke();
            }
        }
        private randomColor(): string {//得到随机的颜色值
            var r = Math.floor(Math.random() * 256);
            var g = Math.floor(Math.random() * 256);
            var b = Math.floor(Math.random() * 256);
            return "rgb(" + r + "," + g + "," + b + ")";
        }
        private async submitEvent () {
            let _this: any = this
            if(!this.phone) {
                _this.$message.warning('请输入手机号')
                return
            }
            if(this.phone && !/^1[3456789]\d{9}$/.test(this.phone)) {
                _this.$message.warning('请输入正确的手机号')
                return
            }
            if(!this.content) {
                _this.$message.warning('请输入内容')
                return
            }
            if(!this.msgcode) {
                _this.$message.warning('请输入验证码')
                return
            }
            if(this.msgcode && this.msgcode.toLowerCase() != this.showNum) {
                _this.$message.warning('验证码输入错误')
                return
            }
            let _res: any = await Caxios.post({ url: `/api/official/schoolmasterMsg`, data: { branchSchool: this.branchSchool, phone: this.phone, content: this.content } })
            if(_res) {
                _this.$message.success('提交成功')
                this.setCommonComponentName('')
            } else {
                _this.$message.warning('操作过于频繁！请两分钟后重试！')
            }
        }
        mounted () {
            this.$nextTick(() => {
                this.mycan = this.$refs.canvasDom;
                this.con = this.mycan.getContext('2d');
                this.conW = this.mycan.width
                this.conH = this.mycan.height
                this.drawFrame()
            })
        }
    }
